/* TopBar.css */
.top-bar {
  grid-column: 1 / 3;
  background-color: #333;
  color: #fff;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.top-bar-left {
  display: flex;
  align-items: center;
}

.top-bar .sidebar-toggle {
  background: none;
  border: none;
  color: #fff;
  font-size: 1rem; /* Reduced font size */
  cursor: pointer;
  margin-right: 20px;
  padding: 5px; /* Added padding */
}

.top-bar a {
  color: #fff;
  text-decoration: none;
}

.top-bar button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

.menu {
  position: relative;
}

.hamburger {
  cursor: pointer;
  font-size: 1rem;
  padding: 5px;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #fff;
  transition: background-color 0.3s, color 0.3s;
}

/* Active (Expanded) State */
.hamburger.active {
  background-color: #0056b3; /* Blue background for active state */
  color: #fff; /* White text */
  border-radius: 4px; /* Add a rounded effect */
}

/* Hover State */
.hamburger:hover {
  background-color: #007bff; /* Lighter blue for hover */
  color: #fff; /* Keep text white */
}

.profile-menu {
  position: relative;
  display: flex;
  align-items: center;
}

.profile-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 20px; /* Add margin to separate from notification bell */
}

.notification-bell {
  position: relative;
  color: #fff;
  font-size: 1.5rem;
  margin-left: 20px; /* Add margin to separate from other elements */
  cursor: pointer;
}

.notification-dot {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #333;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.dropdown-menu span,
.dropdown-menu a,
.dropdown-menu button {
  display: block;
  margin: 5px 0;
}

.dropdown-menu a,
.dropdown-menu button {
  color: #fff;
  text-decoration: none;
  border: none;
  background: none;
  cursor: pointer;
}

/* Mobile styles */
@media (max-width: 768px) {
  .welcome-message {
    display: none;
  }
}

/* Auth Buttons Container */
.auth-buttons {
  display: flex;
  gap: 10px;
}

/* Base Button Style */
.auth-button {
  padding: 8px 16px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

/* Login Button */
.login-btn {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
}

.login-btn:hover {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}

/* Register Button */
.register-btn {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
}

.register-btn:hover {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}
