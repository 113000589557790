/* components/CallCustomer.css */
.call-customer-header {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  background-color: white; /* Ensure the background covers the content beneath */
  z-index: 10;
  padding: 10px 0;
}

.call-customer-header h2 {
  margin-bottom: 10px;
}

.call-customer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filters-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.applied-filters {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filters-actions {
  display: flex;
  gap: 10px;
}

.filters-button {
  padding: 5px 10px;
  font-size: 16px;
}

textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin-bottom: 5px;
}

/* Add these styles for the modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  max-width: 80%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.modal-body {
  padding: 10px 0;
}

.modal-body .filters-form .filter-item {
  margin-bottom: 10px;
}

/* CallCustomer.css */

.call-log-form {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.call-log-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.call-log-form input,
.call-log-form textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.call-log-form button {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.call-log-form button:hover {
  background-color: #0056b3;
}
