.customer-details-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.customer-details {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.customer-info, .customer-payment-options, .customer-notes, .associated-clients, .documents-section, .metadata-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.customer-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.customer-actions button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.customer-actions button:hover {
  background-color: #0056b3;
}

.contact-group {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.add-contact-button, .remove-contact-button, .submit-button, .cancel-button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-contact-button {
  background-color: #28a745;
  color: #fff;
}

.add-contact-button:hover {
  background-color: #218838;
}

.remove-contact-button {
  background-color: #dc3545;
  color: #fff;
}

.remove-contact-button:hover {
  background-color: #c82333;
}

.submit-button {
  background-color: #007bff;
  color: #fff;
}

.submit-button:hover {
  background-color: #0056b3;
}

.cancel-button {
  background-color: #6c757d;
  color: #fff;
}

.cancel-button:hover {
  background-color: #5a6268;
}
