.compact-row td {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  line-height: 1.2 !important;
}

/* CallCustomerSummary.css */
.compact-card .ant-card-body {
  padding: 8px 12px !important;
}
