/* ApplyCreditMemo.css */

.credit-memo-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .invoice-details {
    margin-bottom: 20px;
  }
  
  .invoice-details p {
    margin: 5px 0;
  }
  
  .credit-memo-form {
    display: flex;
    flex-direction: column;
  }
  
  .submit-button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  