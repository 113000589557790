/* AddSchedule.css */

tfoot td {
  font-weight: bold;
  background-color: #f2f2f2;
}

.total-amounts {
  margin-bottom: 20px;
}

.required {
  color: red;
}

.table-container-add-schedule {
  margin-bottom: 0px !important;
}

/* 🔹 Full-screen saving overlay */
.saving-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  color: white;
  font-size: 1.5rem;
}

.saving-overlay p {
  margin-top: 10px;
}
