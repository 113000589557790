.employee-details-container {
    margin: 20px;
  }
  
  .employee-details {
    margin-bottom: 20px;
  }
  
  .employee-details-form .submit-button,
  .employee-details-form .cancel-button,
  .employee-details .edit-button {
    margin-right: 10px;
  }
  