.add-supplier {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .add-supplier h1 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .add-supplier form {
    display: flex;
    flex-direction: column;
  }
  
  .add-supplier label {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .add-supplier input {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .add-supplier button {
    padding: 10px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .add-supplier button:hover {
    background-color: #0056b3;
  }
  
  .message {
    color: green;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .error {
    color: red;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  