/* CustomerDeposit.css */

.table-container-customer-deposit {
    margin-bottom: 0px !important;
  }
  
  .customer-deposit-container {
    padding: 0px 10px 0px;
    display: flex;
    position: relative;
  }
  
  .drawer-content {
    padding: 16px;
    background-color: #f7f7f7;
    border-radius: 8px;
    height: calc(100vh - 100px);
    overflow-y: auto;
  }
  
  .drawer-collapsed .drawer-content {
    display: none;
  }
  
  .drawer-toggle {
    position: absolute;
    top: 20px;
    right: -20px;
    z-index: 1000;
    background-color: #1890ff;
    border: none;
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .drawer-toggle-collapsed {
    right: -40px;
  }
  
  .drawer-toggle-flash {
    animation: flash 1s infinite alternate;
  }
  
  @keyframes flash {
    from {
      background-color: #1890ff;
    }
    to {
      background-color: #ff4d4f;
    }
  }
  
  .total-amounts {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  
  .table-container-customer-deposit {
    margin-bottom: 16px;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-start;
  }
  
  .drawer-collapsed .fixed-form-buttons {
    left: 20px;
  }
  
  .pinned-customers {
    margin-bottom: 10px;
  }
  
  .pending-actions-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    width: 60%;
    max-width: 800px;
    max-height: 80vh;
    overflow-y: auto;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 1050;
  }
  
  .pending-actions-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1040;
  }
  
  .pending-actions-portal {
    position: relative;
    z-index: 1100;
  }
  
  /* Ensures the table cell content truncates properly */
  .ellipsis {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-tbody > tr:not(.ant-table-measure-row) > td {
    padding: 4px 8px !important; /* Adjust padding as needed */
  }
  
  .table-container-customer-deposit .ant-table-tbody {
    height: calc(100vh - 525px);
  }
  
  .ant-form-item-has-error .ant-input,
  .ant-form-item-has-error .ant-select-selector {
      border-color: #ff4d4f !important; /* Red border for errors */
      box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  }
  
  .ant-form-item-has-error .ant-input:focus,
  .ant-form-item-has-error .ant-select-selector:focus {
      border-color: #ff7875 !important;
      box-shadow: 0 0 0 2px rgba(255, 121, 121, 0.5);
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8); /* ✅ Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* ✅ Ensure it appears above all content */
  }
  
  .spin-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .loading-text {
    font-size: 18px;
    font-weight: bold;
    color: #333; /* ✅ Ensure it's visible */
    margin-top: 10px;
  }
  
  /* Why did I do this? It caused issues with the antd column filter checkboxes */
  /* .ant-checkbox-wrapper {
    display: flex;
    align-items: center; 
    justify-content: center; 
  }
  
  .ant-checkbox {
    transform: scale(1.2); 
    margin: auto; 
  } */
  

  